import { browser } from '$app/environment';
import { init, register, locale as localeStore } from 'svelte-i18n';
import { derived } from 'svelte/store';
import { locales } from '$lib/l10n';

// Preładowanie plików językowych
import en from './lang/en.json';
import pl from './lang/pl.json';

export const isLocaleLoaded = derived(localeStore, ($locale) => typeof $locale === 'string');

const defaultLocale = 'en';

function language(locale: string): string {
	return locale.replace("_", "-").split("-")[0];
}

function supported(locale: string): string {
	if (Object.keys(locales).includes(locale)) {
		return locale;
	} else {
		return defaultLocale;
	}
}

export function mapLocale(locale: string): string {
	const lang = language(locale);
	return supported(lang);
}

function getInitialLocale(): string {
	if (!browser) return defaultLocale;
	const browserLocale = window.navigator.language;
	return mapLocale(browserLocale);
}

register('en', () => Promise.resolve(en));
register('pl', () => Promise.resolve(pl));

//asynchronous
// register('en', () => import('./lang/en.json'));
// register('pl', () => import('./lang/pl.json'));

init({
	fallbackLocale: defaultLocale,
	initialLocale: getInitialLocale()
});

export { localeStore as locale };
